@font-face {
  font-family: 'Quilon-Bold';
  src: url('./fonts/Quilon-Bold.woff2') format('woff2'),
       url('./fonts/Quilon-Bold.woff') format('woff'),
       url('./fonts/Quilon-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Alpino-Bold';
  src: url('./fonts/Alpino-Bold.woff2') format('woff2'),
       url('./fonts/Alpino-Bold.woff') format('woff'),
       url('./fonts/Alpino-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Alpino-Black';
  src: url('./fonts/Alpino-Black.woff2') format('woff2'),
       url('./fonts/Alpino-Black.woff') format('woff'),
       url('./fonts/Alpino-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Acumin Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro'), url('./fonts/Acumin-RPro.woff') format('woff');
}

@font-face {
  font-family: 'Acumin Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro Bold'), url('./fonts/Acumin-BdPro.woff') format('woff');
}

/* SATOSHI FONTS */
@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('./fonts/Satoshi-MediumItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-MediumItalic.woff') format('woff'),
       url('./fonts/Satoshi-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('./fonts/Satoshi-BlackItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-BlackItalic.woff') format('woff'),
       url('./fonts/Satoshi-BlackItalic.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('./fonts/Satoshi-LightItalic.woff2') format('woff2'),
       url('./fonts/Satoshi-LightItalic.woff') format('woff'),
       url('./fonts/Satoshi-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Black';
  src: url('./fonts/Satoshi-Black.woff2') format('woff2'),
       url('./fonts/Satoshi-Black.woff') format('woff'),
       url('./fonts/Satoshi-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Light';
  src: url('./fonts/Satoshi-Light.woff2') format('woff2'),
       url('./fonts/Satoshi-Light.woff') format('woff'),
       url('./fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./fonts/Satoshi-Medium.woff2') format('woff2'),
       url('./fonts/Satoshi-Medium.woff') format('woff'),
       url('./fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: "000webfont";
  src: url("./fonts/000webfont.eot?") format("eot"),
       url("./fonts/000webfont.woff") format("woff"),
       url("./fonts/000webfont.ttf") format("truetype"),
       url("./fonts/000webfont.svg#000webfont") format("svg");
       font-weight:normal;
       font-style:normal;
}

// ----------------------
// ----------------------
// ANIMATION DEFINITIONS
// ______________________
// ______________________

@keyframes spinFade { 
  from { 
      transform: rotate(0deg); 
  } to { 
      transform: rotate(360deg); 
  }
}

@keyframes spinFade {
  0%   {transform: rotate(0deg); opacity: 0;}
  5%   {opacity: 1;}
  45%   {opacity: 1;}
  50%   {opacity: 0;}
  100%   {transform: rotate(360deg); opacity: 0;}
}
@keyframes fadeText {
  0%   {opacity: 0;}
  50%   {opacity: 1;}
  100%   {opacity: 0;}
}
// @keyframes fadePage {
//   0%   {opacity: 1;}
//   95%   {opacity: 1;}
//   100%   {opacity: 0;}
// }

// _______
// _______
// LOADER
// -------
// -------

.loader-page {
  position: absolute;
  display: flex;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  align-items: center;
  // animation-name: fadePage;
  // animation-duration: 2500ms;
  // animation-iteration-count: 1;
  // animation-timing-function: linear;
  .loader-page---wrapper {
    flex: 1;
    align-content: center;
    img {
      width: 64px;
      height: 64px;
      position: relative;
      // -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
      // filter: invert(100%);
      animation-name: spinFade;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: spinFade;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      @media (max-width: 960px) {

      }
    }
    p {
      color: #e5ff46;
      position: relative;
      margin-top: -2px;
      font-size: 10px;
      animation-name: fadeText;
      animation-duration: 2500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

// -----------
// APP SITE
// _____________

.App {
  text-align: center;
}


* {
  box-sizing: border-box;
}

article {
  position: absolute;
  background-image: url('background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // mix-blend-mode: difference;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  @media (max-width: 960px) {
    height: 100%
  }
}

.logo {
  position: relative;
  z-index: 3;
  display: inline-block;
  width: 100%;
  // position: absolute;
  border: none;
  background: none;
  // left: 0;
  text-decoration: none;
  font-family: 'Quilon-Bold', Arial, Helvetica, sans-serif;
  letter-spacing: 0.3em;
  font-size: 3rem;
  margin-top: 60px;
  // color: #e5ff46;
  color: #000;
  text-align: center;
  @media (max-width: 960px) {
    
    font-size: 2rem;
  }
}

.link {
  position: relative;
  z-index: 3;
  display: inline-block;
  font-family: 'Satoshi-Medium', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  // color: #e5ff46;
  color: #000;
  padding: 40px 0;
  // text-shadow: 1px 1px 2px black;
  @media (max-width: 960px) {
    font-family: 'Satoshi-Medium', Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 10px 0;
    width: 100%;
    display: block;
    // text-align: center;
  }
  /* animation */
  /* animation-duration: 0.001s;
  animation-iteration-count: infinite;
  transform-origin: bottom; */
  // transition: 0.3s;
  
}

.link:hover {
  // font-size: 36px;
  // text-decoration: underline;
  color: #fff;
}

section.wrapper--top {
  display: flex;
  position: absolute;
  top: 50px;
  right: 100px;
  .link {
    flex: 1;
    text-align: right;
    padding-top: 20px;
  }
}

section.wrapper--bottom {
  position: absolute;
  bottom: 20px;
  bottom: 20px;
  left: 100px;
  .link {
    margin-right: 30px;
  }
  @media (max-width: 960px) {
    width: 100%;
    left: auto;
    margin-bottom: 20px;
  }
}

// VIDEO BG

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.poster,
video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.poster {
  z-index: 1;
}

// KAFKA

.link-kafka {
  z-index: 2;
  mix-blend-mode: difference;
  text-decoration: overline;
  padding:10px 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFF;
  cursor: pointer;
  letter-spacing: 1px;
  font-family: '000webfont', Arial, Helvetica, sans-serif;
  font-size: 26px;
  background: none;
  transition: all .4s cubic-bezier(0.075, 0.82, 0.165, 1);
  @media (max-width: 960px) {
    font-size: 16px;
  }
  &:hover {
    font-size: 50px;
    text-decoration: none;
    span {
      color: #000;
    }
    @media (max-width: 960px) {
      font-size: 20px;
      color:#FFF;
      padding: 10px;
      background: none;
    }
  }
}