
.modal-overlay {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
}

.modal-wrapper {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto; 
    outline: 0;
}

.modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: 40px;
    padding: 2rem;
    @media (max-width: 960px) {
        margin: auto;
        max-width: none;
        width: 100vw;
    }
    // height: 94vh;
    .logo {
        cursor: pointer;
        color: #000;
        // top: 20px;
        // left: 30px;
        margin: 0;
        margin-top: -12px;
        // position: absolute;
        @media (max-width: 960px) {
            margin-top: 30px;
            padding: 0;
            padding-left: 12px;
            text-align: center;
        }
    }
}

.modal-content {
    height: 100%;
    display: flex;
}

.modal---valign {
    display: flex;
    flex: 1;
    align-self: center;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 30px;
    top: 20px;
    @media (max-width: 960px) {
        right: 10px;
        top: 10px;
    }
}

.modal-close-button {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    background: none;
    color: #000;
    opacity: 1;
    cursor: pointer;
    border: none;
    @media (max-width: 960px) {
        right: 10px;
        top: 10px;
        padding: 0;
        padding-right: 4px;
    }
}

.modal--about, .modal--contact {
    height: calc(100vh - 80px);
}

// CONTACT
.modal--contact {
    .modal--wrapper {
        height: 100%;
        display: flex;
        @media (max-width: 960px) {
            height: auto;
        }
        p {
            align-self: center;
            margin-top: -42px;
            width: 100%;
            font-family: 'Satoshi-Black', Arial, Helvetica, sans-serif;
            letter-spacing: 8px;
            font-size: 16px;
            text-align: center;
            @media (max-width: 960px) {
                margin-top: 60%;
            }
        }
    }
}
// ABOUT
.modal--about {
    min-height: 590px;
    @media (max-width: 960px) {
        height: 100vh;
    }
    .modal-content--about {
        text-align: center;
        letter-spacing: 1px;
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;
        margin-top: -30px;
        // height: 80vh;
        @media (max-width: 960px) {
            height: auto;
            font-size: 14px;
        }
        .modal---valign {
            flex-direction: column;
            .icon {
                height: 120px;
                @media (max-width: 960px) {
                    height: 100px;
                    padding-top: 40px;
                }
                img {
                    height: fit-content;
                    width: 80px;
                    height: 80px;
                    @media (max-width: 960px) {
                        height: auto;
                    }
                }
            }
            .text-1 {
                height: fit-content;
                font-family: 'Satoshi-Medium', Arial, Helvetica, sans-serif;
                @media (max-width: 960px) {
                    margin-top: 80px;
                }
                max-width: 600px;
                margin: 0 auto;
            }
            .mail {
                cursor: pointer;
                display: block;
                margin-top: 40px;
                height: fit-content;
                font-family: 'Satoshi-Black', Arial, Helvetica, sans-serif;
                text-decoration: none;
                color: #000;
                letter-spacing: 8px;
                &:hover {
                    color: #e5ff46;
                }
            }
        }
    }
}

// EXHIBITIONS
.modal--exhibitions {
    .modal-content--exhibitions {
        margin-top: 50px;
        height: fit-content;
        @media (max-width: 960px) {
            margin-top: 20px;
            flex-direction: column;
        }
        .panel-1, .panel-2 {
            text-align: center;
            a {
                text-decoration: none;
                color: #000;
                display: inline-block;
                &:hover {
                    color: #e5ff46;
                }
            }
        }
        .panel-1 {
            flex: 1;
            // margin-right: 6%;
            align-items: center;
            @media (max-width: 960px) {
                margin-right: 0; 
                margin-top: 20px;
            }
        }
        .panel-2 {
            flex: 1;
            
        }
        h3 {
            align-items: center;
            font-family: 'Satoshi-BlackItalic', Arial, Helvetica, sans-serif;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 20px;
            margin: 0;
        }
        h4 {
            align-items: center;
            font-family: 'Satoshi-LightItalic', Arial, Helvetica, sans-serif;
            letter-spacing: 1px;
            font-size: 18px;
            span.expo-name {
                font-family: 'Satoshi-BlackItalic', Arial, Helvetica, sans-serif;
                background: none;
            }
            span {
                background: #e5ff46;
            }
            span.address {
                display: inline-block;
                font-family: 'Satoshi-BlackItalic', Arial, Helvetica, sans-serif;
                background: none;
                font-size: 15px;
                padding-top: 20px;
            } 
        }
    }
    ul {
        list-style: none;
        position: relative;
        padding: 0;
        text-transform: uppercase;
        text-align: center;
        // margin-top: 80px;
        width: 400px;
        margin: 40px auto;
        @media (max-width: 960px) {
            margin: 40px auto;
            width: auto;
        }
        li {
            // cursor: pointer;
            letter-spacing: 3px;
            font-family: 'Alpino-Black', Arial, Helvetica, sans-serif;
            font-size: 23px;
            line-height: 26px;
            // margin-bottom: 5px;
            &:hover {
                // color: #e5ff46;
            }
            @media (max-width: 960px) {
                line-height: 21px;
                font-size: 18px;
                &:hover {
                    color: #000;
                }
                &.opened {
                    color: #e5ff46;
                }
            }
        }
    }

    .img-loader {
        position: absolute;
        // z-index: 2;
        top: 50%;
        margin-top: -50px;
        margin-left: -330px;
        // top: 10%;
        width: 50px;
        @media (max-width: 960px) {
            // position: relative;
            margin: 0;
            width: 20px;
            left: 50%;
            margin-left: -6px;
            margin-top: -20px;
        }
        @media (min-width: 1200px) {
            margin-left: -350px;
        }
        @media (min-width: 1400px) {
            margin-left: -380px;
        }
        @media (min-width: 1600px) {
            margin-left: -440px;
        }
        @media (min-width: 2000px) {
            margin-left: -500px;
        }
    }
    .img-artist-wrapper {
        position: absolute;
        // z-index: 3;
        top: 30px;
        margin-left: -330px;
        left: 10%;
        width: 300px;
        .img-artist {
            width: 100%;
            
        }
        @media (max-width: 960px) {
            position: relative;
            margin-left: 0;
            top: 0;
            left: 0;
            bottom: auto;
            max-width: none;
            width: 100%;
            padding: 10px 0;
        }
        @media (min-width: 1200px) {
            margin-left: -350px;
        }
        @media (min-width: 1400px) {
            margin-left: -380px;
        }
        @media (min-width: 1600px) {
            margin-left: -440px;
        }
        @media (min-width: 2000px) {
            margin-left: -500px;
        }
    }
}